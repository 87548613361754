import $ from "jquery";
globalThis.jQuery = $;

// GENERAL STYLING
import './styles.scss';


(function ($) {

    $(function () {

        'use strict';


    });

})(jQuery);